import { chartOptions, plotColors, treeGraphColors } from "../../../constants";

const TreeGraphOptions = {
  chart: {
    type: "treegraph",
    style: { ...chartOptions.typography },
    backgroundColor: "transparent",
  },
  colors: [treeGraphColors.normal],
  exporting: {
    ...chartOptions.export,
  },
  credits: {
    enabled: true,
  },
  title: {
    ...chartOptions.title,
  },
  tooltip: {
    ...chartOptions.tooltip,
    enabled: false,
    //distance: 10,
  },
  legend: {
    ...chartOptions.legend,
  },
  plotOptions: {
    treegraph: {
      fillSpace: true,
      states: {
        hover: {
          borderWidth: 0,
        },
      },
    },
  },
  series: [],
};

export default TreeGraphOptions;
