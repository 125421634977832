import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

//Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
import HighchartsTreeMap from "highcharts/modules/treemap";
import HighchartsTreeGraph from "highcharts/modules/treegraph";
HighchartsTreeMap(Highcharts);
HighchartsTreeGraph(Highcharts);

import { useResizeDetector } from "react-resize-detector";
import TreeGraphOptions from "./TreeGraphOptions";
import { treeGraphColors } from "../../../constants";
import {
  convertToLabel,
  replaceWithNonBreakingSpace,
  validateArrayData,
  validateObjectData,
} from "../../../utils";

import MOCK_DATA from "./data.json";

// Convert to Chart data
const convertToChartData = (data, rootNode, radius) => {
  let colorNodes = [];
  let tempArr = [];

  if (validateArrayData(data) && rootNode && validateObjectData(radius)) {
    data?.map((obj) => {
      if (colorNodes.includes(obj["parent"])) return;
      colorNodes.push(obj["parent"]);
    });

    data?.map((obj) => {
      let tempObj = {};
      tempObj = { ...obj };
      tempObj["marker"] = {};
      tempObj["marker"]["radius"] = radius.child;
      const parentNode = colorNodes.find((val) => val === obj["id"]);
      if (rootNode === obj["id"]) {
        tempObj["color"] = treeGraphColors.root;
        tempObj["marker"]["radius"] = radius.parent;
      } else if (parentNode) {
        tempObj["color"] = treeGraphColors.parents;
        tempObj["marker"]["radius"] = radius.parent;
      } else if (obj["line type"] === "dashed") {
        tempObj["color"] = treeGraphColors.others;
      } else {
        tempObj["color"] = treeGraphColors.nodes;
      }

      tempArr.push(tempObj);
    });
  }

  console.log("temp", tempArr);

  return tempArr;
};

const TreeGraphHC = ({
  data,
  rootNode,
  unitMeasurement,
  title,
  chartHeight,
  isChartTitleDisabled,
  isEnableExporting,
  isCollapsedDefault,
  topSpace,
  bottomSpace,
  leftSpace,
  rightSpace,
  markerRadius,
  isExpandOneNodeAtTime,
  orderNodesByData,
}) => {
  const [chartOptions, setChartOptions] = useState(TreeGraphOptions);
  const { width, ref } = useResizeDetector();
  const chartRef = useRef(null);

  console.log("GUIDELINE_DATA", data);
  useEffect(() => {
    if (data && validateArrayData(data)) {
      setChartOptions({
        ...TreeGraphOptions,
        chart: {
          type: "treegraph",
          height: `${chartHeight}px`,
          spacingBottom: bottomSpace,
          spacingLeft: leftSpace,
          spacingRight: rightSpace,
          spacingTop: topSpace,
        },
        title: {
          ...TreeGraphOptions.title,
          text: !isChartTitleDisabled ? title : null,
        },
        exporting: {
          enabled: isEnableExporting,
        },
        tooltip: {
          ...TreeGraphOptions.tooltip,
          enabled: true,
          useHTML: true,
          //followPointer: true,
          pointFormatter: function () {
            const explainText = this["Calculation Process"]
              ? this["Calculation Process"]
              : null;
            const nodeUnits = this.units ? this.units : unitMeasurement;
            const confidenceValue = this.confidence ? this.confidence : null;

            return `<strong>${convertToLabel(this.id)}</strong>${
              this.value ? `: ${this.value}` : ""
            } ${nodeUnits} ${
              confidenceValue
                ? `<br> <strong>Confidence</strong>: ` + confidenceValue
                : ""
            } ${
              explainText
                ? ` <br><div class="node-explainer">${explainText}</div>`
                : ""
            }`;
          },
        },
        plotOptions: {
          ...TreeGraphOptions.plotOptions,
          treegraph: {
            fillSpace: true,
          },
        },
        series: [
          {
            type: "treegraph",
            clip: false,
            reversed: orderNodesByData,
            collapseButton: {
              x: -6,
              y: 6,
            },
            // marker: {
            //   symbol: "circle",
            //   radius: 25,
            // },
            data: convertToChartData(data, rootNode, markerRadius),
            dataLabels: {
              align: "left",
              formatter: function () {
                const updatedLabel = replaceWithNonBreakingSpace(this.point.id);
                return updatedLabel;
              },
              style: {
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "normal",
                //textOutline: "3px #ffffff",
                color: "#333333",
                whiteSpace: "nowrap",
                textOverflow: "none",
              },
              inside: false,
              //x: 0,
              y: -8,
              crop: false,
              overflow: "justify",
            },
            link: {
              color: "#aaaaaa",
            },
            levels: [
              {
                level: 1,
                levelIsConstant: false,
              },
              {
                level: 2,
                collapsed: isCollapsedDefault,
              },
            ],
            point: {
              events: {
                click: function () {
                  if (isExpandOneNodeAtTime) {
                    const node = this;

                    node.series.points.forEach(function (point) {
                      if (point.parent === node.parent && point !== node) {
                        point.update(
                          {
                            collapsed: true,
                          },
                          false
                        );
                      } else if (node.id === point.parent) {
                        point.update(
                          {
                            collapsed: true,
                          },
                          false
                        );
                      } else return;
                    });

                    // Redraw the chart
                    node.series.chart.redraw();
                  } else return;
                },
              },
            },
          },
        ],
      });
    } else {
      setChartOptions(TreeGraphOptions);
    }
  }, [data]);

  useEffect(() => {
    chartRef.current?.chart.reflow();
  }, [width]);

  //console.log("chartOptions", chartOptions);
  return (
    <>
      {data && validateArrayData(data) ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={chartRef}
        />
      ) : null}
    </>
  );
};

TreeGraphHC.propTypes = {
  data: PropTypes.array,
  unitMeasurement: PropTypes.string,
  title: PropTypes.string,
  chartHeight: PropTypes.number,
  isChartTitleDisabled: PropTypes.bool,
  isEnableExporting: PropTypes.bool,
  isCollapsedDefault: PropTypes.bool,
  markerRadius: PropTypes.object,
  isExpandOneNodeAtTime: PropTypes.bool,
  orderNodesByData: PropTypes.bool,
};

TreeGraphHC.defaultProps = {
  data: MOCK_DATA,
  unitMeasurement: "mtCO2e",
  title: "Tree Graph Chart",
  chartHeight: 600,
  isChartTitleDisabled: true,
  isEnableExporting: false,
  isCollapsedDefault: false,
  topSpace: 30,
  bottomSpace: 30,
  leftSpace: 30,
  rightSpace: 30,
  markerRadius: {
    parent: 25,
    child: 25,
  },
  isExpandOneNodeAtTime: false,
  orderNodesByData: true,
};

export default TreeGraphHC;
