import React, { lazy } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import config from "../config";
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import PrivateRoute from "../components/PrivateRoute";
import Guideline from "../views/Guideline/guideline.page";

// Auth Pages
const LoginPage = Loadable(lazy(() => import("../views/auth/Login")));

const PCFPage = Loadable(lazy(() => import("../views/PCF/pcf.page")));
const PCFPageV1 = Loadable(lazy(() => import("../views/PCF/pcfv1.page")));

// API integrated CCF
const CCFPage = Loadable(lazy(() => import("../views/CCF/ccf.page")));

// Coca Cola
const CocaColaPCF = Loadable(
  lazy(() => import("../views/CocaColaPCF/cocpcf.page"))
);
const CocaColaCCF = Loadable(
  lazy(() => import("../views/CocaColaCCF/cocccf.page"))
);
const CocaColaCoffeePCF = Loadable(
  lazy(() => import("../views/CocaColaCoffeePCF/coccoffeepcf.page"))
);

// CCF Search
const SearchCCF = Loadable(
  lazy(() => import("../views/SearchCCF/search.ccf.page"))
);

//-----------------------|| ROUTING RENDER ||-----------------------//
const AppRoutes = () => {
  return (
    <Routes>
      {/* Main Routes */}
      <Route path="/" element={<Navigate replace to={config.defaultPath} />} />

      <Route path="pcf" element={<MainLayout />}>
        <Route index element={<PCFPage />} />
      </Route>
      <Route path="ccf" element={<MainLayout />}>
        <Route index element={<CCFPage />} />
      </Route>

      <Route path="updated-pcf" element={<MainLayout />}>
        <Route index element={<PCFPageV1 />} />
      </Route>

      <Route path="coca-cola" element={<MainLayout />}>
        <Route path="pcf" element={<CocaColaPCF />} />
        <Route path="ccf" element={<CocaColaCCF />} />
      </Route>

      <Route path="coca-cola-coffee" element={<MainLayout />}>
        <Route path="pcf" element={<CocaColaCoffeePCF />} />
      </Route>

      <Route path="ccf-search" element={<MainLayout />}>
        <Route index element={<SearchCCF />} />
      </Route>

      <Route path="csrd-guideline-overview" element={<MainLayout />}>
        <Route index element={<Guideline />} />
      </Route>

      {/* Auth Routes */}
      {/* <Route path="/login" element={<LoginPage />} /> */}
    </Routes>
  );
};

export default AppRoutes;
